* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    font-family: 'Open Sans';
}

html,
body {
    min-height: 100%;
}

input {
    border: 0;
    background-color: transparent;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button {
    border: 0px;
    background-color: transparent;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    display: none;
}

:root {
    --wcm-z-index: 1302 !important;
}

/* .MuiModal-backdrop  {
    background-color: rgba(0, 0, 0, 0.7) !important;
} */